<template>
  <b-card
    body-class="pb-50"
  >
    <h6 class="text-muted">{{ title }}</h6>
    <div
      v-if="loading"
      class="relative w-100 pb-4 pt-4"
    >
      <b-overlay
        :show="true"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
    </div>
    <div
      v-if="revenueData"
    >
      <h2 class="font-weight-bolder mb-1">
        {{ revenueData.total }}
      </h2>
      <!-- chart -->
      <vue-apex-charts
        height="140"
        :options="chartOptionsComputed"
        :series="revenueData.series"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { ref, computed, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BCard,
    BOverlay,
    VueApexCharts,
  },
  props: {
    chartOptions: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    api: {
      type: String,
      required: true,
    },
    selectedFilter: {
      type: [Object, String, Number, null],
      default: null,
    },
  },
  setup(props) {
    const { route } = useRouter()
    const $barColor = '#f3f3f3'

    const barChartOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          top: -15,
          bottom: -15,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          colors: {
            backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
            backgroundBarRadius: 5,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: [$themeColors.warning],

      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    }

    const chartOptionsComputed = computed(() => {
      if (props.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(barChartOptions))
        return options
      }
      return props.chartOptions
    })

    const loading = ref(false)
    const revenueData = ref(null)

    const fetchData = () => {
      loading.value = true
      revenueData.value = null
      store.dispatch('app/fetchData', {
        api: props.api,
        params: {
          period: props.selectedFilter,
          partner_id: route.value.params.partner_id,
          branche_id: route.value.params.branche_id,
        },
      }).then(res => {
        const { data, success } = res.data

        if (success) {
          revenueData.value = data
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    fetchData()

    watch(() => props.selectedFilter, () => fetchData())
    watch([route], () => fetchData())

    return {
      chartOptionsComputed,
      loading,
      revenueData,
    }
  },
}
</script>
