        <template>
  <div
          class="h-100"
      >
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
              <b-row
                              >
                                  <b-col
                                                                                                                          sm="4"
                                                            >
                                            <general-statistics
  title="Сеть"
  api="http://api.bc-sport.ru/api/dashboard/network"
/>                  </b-col>
                                  <b-col
                                                                                                                          sm="4"
                                                            >
                                            <general-statistics
  title="Общая статистика"
  api="http://api.bc-sport.ru/api/dashboard/general-statistics"
/>                  </b-col>
                                  <b-col
                                                                                                                          sm="4"
                                                            >
                                            <active-subscriptions
  title="Действующие абонементы"
  api="http://api.bc-sport.ru/api/dashboard/active-subscriptions"
/>                  </b-col>
                                </b-row>
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <date-filter
    :value.sync="selectedFilter"
  />
            </b-col>
                                        <b-col
                                                                                      sm="3"
                                        >
                            <revenue
  title="Выручка"
  api="http://api.bc-sport.ru/api/dashboard/revenue"
    :selectedFilter="selectedFilter"
  />
            </b-col>
                                        <b-col
                                                                                      sm="3"
                                        >
                            <sales
  title="Проданные абонементы"
  api="http://api.bc-sport.ru/api/dashboard/sales"
    :selectedFilter="selectedFilter"
  />
            </b-col>
                                        <b-col
                                                                                      sm="6"
                                        >
                            <attendance
  title="Посещаемость"
  api="http://api.bc-sport.ru/api/dashboard/attendance"
    :selectedFilter="selectedFilter"
  />
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <attendance-table
  title="Таблица посещаемости по группам"
  api="http://api.bc-sport.ru/api/dashboard/attendance-table"
    :selectedFilter="selectedFilter"
  />
            </b-col>
                            </b-row>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                                    import GeneralStatistics from '@core/components/dashboard/GeneralStatistics.vue'
                                                                                              import ActiveSubscriptions from '@core/components/dashboard/ActiveSubscriptions.vue'
                                                                          import DateFilter from '@core/components/dashboard/DateFilter.vue'
                                                              import Revenue from '@core/components/dashboard/Revenue.vue'
                                                              import Sales from '@core/components/dashboard/Sales.vue'
                                                              import Attendance from '@core/components/dashboard/Attendance.vue'
                                                              import AttendanceTable from '@core/components/dashboard/AttendanceTable.vue'
                            
export default {
  components: {
    BRow,
    BCol,

                                                                          GeneralStatistics,
                                                                                                  ActiveSubscriptions,
                                                                                            DateFilter,
                                                                              Revenue,
                                                                              Sales,
                                                                              Attendance,
                                                                              AttendanceTable,
                                          },
  setup() {
                const selectedFilter = ref('day')
          
          return {
            selectedFilter,
            }
      },
}
</script>
    