<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <h6 class="text-muted">{{ title }}</h6>
    </b-card-header>
    <div
      v-if="loading"
      class="relative w-100 pb-4 pt-4"
    >
      <b-overlay
        :show="true"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
    </div>
    <b-card-body
      v-if="activeSubscriptionsData && activeSubscriptionsData.length"
    >
      <div
        v-for="(item, key) in activeSubscriptionsData"
        :key="key"
        class="transaction-item"
      >
        <b-media no-body class="align-items-center">
          <b-media-aside
            v-if="item.icon"
          >
            <b-avatar
              rounded
              size="42"
              :variant="item.iconVariant"
            >
              <i class="font-size: 18px" :class="item.icon"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ item.name }}
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="item.color"
        >
          {{ item.count }}
        </div>
      </div>
    </b-card-body>
    <b-card-body
      v-else
    >
      Нет абонементов
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
  BMediaBody, BMedia, BMediaAside, BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BOverlay,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { route } = useRouter()
    const activeSubscriptionsData = ref(null)
    const loading = ref(false)

    const fetchData = () => {
      activeSubscriptionsData.value = null
      loading.value = true
      store.dispatch('app/fetchData', {
        api: props.api,
        params: {
          partner_id: route.value.params.partner_id,
          branche_id: route.value.params.branche_id,
        },
      }).then(res => {
        const { data, success } = res.data

        if (success) {
          activeSubscriptionsData.value = data
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    if (props.api) {
      fetchData()
    }

    watch([route], () => fetchData())

    return {
      activeSubscriptionsData,
      loading,
    }
  },
}
</script>
