<template>
  <b-card
    no-body
  >
    <b-card-header>
      <h6 class="text-muted">{{ title }}</h6>
    </b-card-header>
    <div
      v-if="loading"
      class="relative w-100 pb-4 pt-4"
    >
      <b-overlay
        :show="true"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
    </div>
    <b-card-body v-if="attendanceData">
      <b-row>
        <b-col
          v-for="item in attendanceData"
          :key="item.icon"
          md="6"
          class="mb-2"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
  BMedia, BMediaAside, BAvatar, BMediaBody,
  BOverlay, BCardText,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
    BCardText,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    api: {
      type: String,
      required: true,
    },
    selectedFilter: {
      type: [Object, String, Number, null],
      default: null,
    },
  },
  setup(props) {
    const { route } = useRouter()
    const attendanceData = ref(null)
    const loading = ref(false)

    const fetchData = () => {
      if (props.api) {
        loading.value = true
        attendanceData.value = null
        store.dispatch('app/fetchData', {
          api: props.api,
          params: {
            period: props.selectedFilter,
            partner_id: route.value.params.partner_id,
            branche_id: route.value.params.branche_id,
          },
        }).then(res => {
          const { data, success } = res.data

          if (success) {
            attendanceData.value = data
          }

          loading.value = false
        }).catch(() => {
          loading.value = false
        })
      }
    }

    fetchData()

    watch(() => props.selectedFilter, () => fetchData())
    watch([route], () => fetchData())

    return {
      attendanceData,
      loading,
    }
  },
}
</script>
