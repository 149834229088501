<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h6 class="text-muted">{{ title }}</h6>
    <div
      v-if="loading"
      class="relative w-100 pb-4 pt-4"
    >
      <b-overlay
        :show="true"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
    </div>
    <div
      v-if="salesData"
    >
      <h2 class="font-weight-bolder mb-1">
        {{ salesData.total }}
      </h2>
      <!-- chart -->
      <vue-apex-charts
        height="140"
        :options="chartOptions"
        :series="salesData.series"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BCard,
    BOverlay,
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    api: {
      type: String,
      required: true,
    },
    selectedFilter: {
      type: [Object, String, Number, null],
      default: null,
    },
  },
  setup(props) {
    const { route } = useRouter()
    const salesData = ref(null)

    const $trackBgColor = '#EBEBEB'

    const chartOptions = {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        borderColor: $trackBgColor,
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: -30,
          bottom: -10,
        },
      },
      stroke: {
        width: 3,
      },
      colors: [$themeColors.info],
      markers: {
        size: 2,
        colors: $themeColors.info,
        strokeColors: $themeColors.info,
        strokeWidth: 2,
        strokeOpacity: 1,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: '#ffffff',
            strokeColor: $themeColors.info,
            size: 5,
          },
        ],
        shape: 'circle',
        radius: 2,
        hover: {
          size: 3,
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontSize: '0px',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    }

    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      salesData.value = null
      store.dispatch('app/fetchData', {
        api: props.api,
        params: {
          period: props.selectedFilter,
          partner_id: route.value.params.partner_id,
          branche_id: route.value.params.branche_id,
        },
      }).then(res => {
        const { data, success } = res.data

        if (success) {
          salesData.value = data
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    fetchData()

    watch(() => props.selectedFilter, () => fetchData())
    watch([route], () => fetchData())

    return {
      chartOptions,
      loading,
      salesData,
    }
  },
}
</script>
