<template>
  <b-card>
    <div class="d-flex justify-content-end align-items-center">
      <div class="mr-50">Показать за:</div>
      <v-select
        :value="value"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="filters"
        :clearable="false"
        :filterable="false"
        input-id="date-filter"
        :reduce="val => val.value"
        :get-option-label="option => option.label"
        style="max-width: 160px;width: 100%;"
        @input="(val) => {
          $emit('update:value', val)
        }"
      />
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    vSelect,
  },
  props: {
    value: {
      type: [Object, String, Number, null],
      default: null,
    },
  },
  setup() {
    const filters = [
      {
        label: 'День',
        value: 'day',
      },
      {
        label: 'Неделя',
        value: 'week',
      },
      {
        label: 'Месяц',
        value: 'month',
      },
      {
        label: 'Год',
        value: 'year',
      },
    ]

    return {
      filters,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
